<script lang="ts">
	import ProductButtonGroup from "./ProductButtonGroup.svelte";
	import translations from "./AnimatedProductPortfolio.translations.json";
	import { getTranslate } from "../../utils/getTranslate";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";

	import { onMount, tick } from "svelte";
	import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
	import { hideHeaderStore } from "../../stores/hideHeaderStore.js";
	import type { ActionReturn } from "svelte/action";
	import { createPromiseStore, type PromiseStore } from "../../../../core/utils/createPromiseStore.js";
	import { get } from "svelte/store";
	import type { ProductOption } from "./ProductOption.js";
	import { querySizeMappings } from "../../utils/querySizeMappings.js";

	export let preloadElement: HTMLElement | null = null;
	export let active = "car" as ProductOption;

	let length = "short" as "long" | "short";

	let canvas = null as HTMLCanvasElement | null;

	const translate = getTranslate(translations);

	const images: Record<ProductOption, PromiseStore<HTMLImageElement, []>[]> = {
		car: [],
		truck: [],
	};
	const frameCount: Record<ProductOption, Record<typeof length, number>> = {
		car: { short: 52, long: 104 },
		truck: { short: 53, long: 105 },
	};

	async function getImage(
		imageFrame: number,
		active: ProductOption,
		length: "short" | "long",
		preload = 10,
	): Promise<HTMLImageElement> {
		const imports: [number, Promise<{ default: string }>][] = [];
		for (let frame = imageFrame; frame <= Math.min(imageFrame + preload, frameCount[active][length]); frame++) {
			if (frame in images[active]) {
				continue;
			}

			const promise = import(
				`../../assets/${active}/${length}/brano-${active}-${frame.toString().padStart(3, "0")}.webp`
			) as Promise<{
				default: string;
			}>;
			imports.push([frame, promise]);
		}

		await Promise.all(
			imports.map(async ([frame, image]) => {
				const source = (await image).default;
				images[active][frame] = createPromiseStore(
					() =>
						new Promise((resolve) => {
							const img = new Image();
							img.addEventListener("load", () => {
								resolve(img);
							});
							img.src = source;
							img.style.objectFit = "cover";
						}),
				);
				await images[active][frame].call();
			}),
		);

		const promiseStore = images[active][imageFrame];
		if (!promiseStore) {
			throw new Error("No promise for image found");
		}
		const imagePromise = get(promiseStore);
		let image: HTMLImageElement | undefined;
		if (imagePromise.status === "success") {
			image = imagePromise.result;
		} else if (imagePromise.status === "loading") {
			image = await imagePromise.promise;
		}
		if (!image) {
			throw new Error("No image found");
		}
		return image;
	}

	function render(image: HTMLImageElement): void {
		const context = canvas?.getContext("2d");
		if (!canvas || !context) {
			throw new Error("No context found for canvas");
		}

		context.drawImage(image, 0, 0, canvas.width, canvas.height);
	}

	let frame = 1;

	function createGsapAnimation(container: HTMLElement): ActionReturn {
		const scrollTrigger = ScrollTrigger.create({
			trigger: container,
			start: `top top`,
			end: `+=200%`,
			pin: true,
			scrub: 1,
			onUpdate(self) {
				frame = Math.round(100 * self.progress);
			},
		});
		return {
			destroy(): void {
				scrollTrigger.kill();
			},
		};
	}

	$: currentFrame = Math.min(
		Math.max(1, Math.floor((frame / 100) * frameCount[active][length])),
		frameCount[active][length],
	);

	async function onCurrentFrameChanged(frame: number, type: ProductOption): Promise<void> {
		if (typeof window === "undefined") {
			return;
		}

		hideHeaderStore.set(frame > 1 && frame < frameCount[type][length]);

		// Render the first image instantly so we get feedback when switching.
		const firstImage = await getImage(1, type, length, 0);
		if (active === type) {
			render(firstImage);
		}

		const image = await getImage(frame, type, length, frame > 5 ? frameCount[type][length] : 10);
		if (currentFrame === frame && active === type) {
			render(image);
		}
	}

	$: void onCurrentFrameChanged(currentFrame, active);

	onMount(async () => {
		length = window.innerWidth <= querySizeMappings.lg ? "short" : "long";

		void getImage(1, "car", length);
		void getImage(1, "truck", length);
		render(await getImage(1, active, length));

		if (preloadElement) {
			const preloadObserver = new IntersectionObserver(
				(entries) => {
					for (const entry of entries) {
						if (entry.isIntersecting) {
							void getImage(1, "car", length, frameCount.car[length]);
							void getImage(1, "truck", length, frameCount.truck[length]);
						}
					}
				},
				{
					threshold: 0.5,
				},
			);
			preloadObserver.observe(preloadElement);
		}
	});

	$: currentImageStore = images[active][currentFrame];
	let lastLoadedImage: HTMLImageElement | undefined;
	$: {
		if ($currentImageStore && $currentImageStore.status === "success") {
			lastLoadedImage = $currentImageStore.result;
		}
	}

	async function onActiveChanged(_active: ProductOption): Promise<void> {
		if (typeof window === "undefined") {
			return;
		}
		await tick();
		ScrollTrigger.refresh();
	}
	$: void onActiveChanged(active);

	$: imageHeight = lastLoadedImage?.height ?? 0;
	$: imageWidth = lastLoadedImage?.width ?? 0;
</script>

<div class="bg-[#e7e7e7] pt-24 xl:pt-48">
	<div use:createGsapAnimation class="relative grid h-screen grid-rows-[auto,1fr] gap-8">
		<div class="container z-10">
			<div class="flex flex-col items-center justify-between gap-10 pt-4 lg:flex-row lg:pt-0">
				<span
					use:flyInOnScroll
					class="gsap-trigger text-text text-xl font-semibold leading-[1.136] lg:w-[40%] lg:text-4xl lg:leading-[5.25rem]"
				>
					{translate("headline")}
				</span>
				<ProductButtonGroup bind:active />
			</div>
		</div>

		<div class="relative">
			<div class="absolute inset-0" class:lg:-top-36={active === "truck"}>
				<canvas
					bind:this={canvas}
					class="absolute inset-0 h-full w-full object-contain pb-12 md:pb-0"
					width={imageWidth}
					height={imageHeight}
				></canvas>
			</div>

			{#if !$currentImageStore || $currentImageStore.status === "loading"}
				<div class="absolute inset-0 grid place-items-center text-xl">
					<span>{translate("loading")}</span>
				</div>
			{/if}
		</div>
	</div>
</div>
