<script lang="ts">
	import tilesImageSm from "../../assets/images/tiles-image.jpg?webp&w=640&imagetools";
	import tilesImageMd from "../../assets/images/tiles-image.jpg?webp&w=768&imagetools";
	import tilesImageLg from "../../assets/images/tiles-image.jpg?webp&w=1324&imagetools";
	import tilesImageXl from "../../assets/images/tiles-image.jpg?webp&w=1759&imagetools";
	import { getTranslate } from "../../utils/getTranslate";
	import translations from "./HpTiles.translations.json";
	import ImageBanner from "../ImageBanner.svelte";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";

	const translate = getTranslate(translations);

	const tilesTexts = [
		`${translate("rad1")} <br /> ${translate("rad2")}`,
		translate("integration"),
		translate("productPortfolio"),
		translate("jisJit"),
		translate("finance"),
		translate("production"),
		translate("selfSufficiency"),
		translate("decisionMaking"),
	];

	/* eslint-disable svelte/no-at-html-tags */
</script>

<ImageBanner
	title={translate("headline")}
	image={tilesImageXl}
	imageSourcesets={{
		"<sm": tilesImageSm,
		"<md": tilesImageMd,
		"<lg": tilesImageLg,
		"<xl": tilesImageXl,
	}}
>
	<div class="flex w-full lg:justify-end">
		<div class="flex w-full grid-cols-2 flex-col gap-2 md:w-auto md:max-w-[70%] lg:grid lg:gap-[1.375rem]">
			{#each tilesTexts as text}
				<div
					use:flyInOnScroll
					class="flex min-h-[4.125rem] w-full items-end bg-white/10 px-[3.75rem] py-[0.89375rem] backdrop-blur-md md:max-w-[36.125rem] lg:h-[8rem]"
				>
					<p
						class="text-secondary m-0 p-0 text-[1.125rem] font-bold leading-[1.277] lg:w-11/12 lg:text-[1.75rem] lg:font-normal lg:leading-[1.3157]"
					>
						{@html text}
					</p>
				</div>
			{/each}
		</div>
	</div>
</ImageBanner>
