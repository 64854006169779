<script lang="ts">
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import translations from "./Factory.translations.json";

	import { onMount } from "svelte";
	import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
	import FactoryLabel from "./FactoryLabel.svelte";
	import { getTranslate } from "../../utils/getTranslate.js";
	import Link from "../Link.svelte";
	import { hideHeaderStore } from "../../stores/hideHeaderStore.js";
	import type { ActionReturn } from "svelte/action";
	import { createPromiseStore, type PromiseStore } from "../../../../core/utils/createPromiseStore.js";
	import { get } from "svelte/store";
	import { technologyPathMap } from "../../../../core/schema/paths/technologyPathMap.js";
	import { getTenant } from "../../contexts/tenantContextKey.js";
	import { getPathInLocale } from "../../../../core/schema/paths/getPathInLocale.js";
	import { querySizeMappings } from "../../utils/querySizeMappings.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";

	export let preloadElement: HTMLElement | null = null;

	let canvas: HTMLCanvasElement | undefined;
	const { locale } = getTenant();
	const frameCount = 49;
	const images: PromiseStore<HTMLImageElement, []>[] = [];

	async function getImage(imageFrame: number, preload = 10): Promise<HTMLImageElement> {
		const imports: [number, Promise<{ default: string }>][] = [];
		for (let frame = imageFrame; frame <= Math.min(imageFrame + preload, frameCount); frame++) {
			if (frame in images) {
				continue;
			}

			const promise = import(`../../assets/factory/${frame.toString()}.webp`) as Promise<{
				default: string;
			}>;
			imports.push([frame, promise]);
		}

		await Promise.all(
			imports.map(async ([frame, image]) => {
				const source = (await image).default;
				images[frame] = createPromiseStore(
					() =>
						new Promise((resolve) => {
							const img = new Image();
							img.addEventListener("load", () => {
								resolve(img);
							});
							img.src = source;
							img.style.objectFit = "cover";
						}),
				);
				await images[frame].call();
			}),
		);

		const promiseStore = images[imageFrame];
		if (!promiseStore) {
			throw new Error("No promise for image found");
		}
		const imagePromise = get(promiseStore);
		let image: HTMLImageElement | undefined;
		if (imagePromise.status === "success") {
			image = imagePromise.result;
		} else if (imagePromise.status === "loading") {
			image = await imagePromise.promise;
		}
		if (!image) {
			throw new Error("No image found");
		}
		return image;
	}

	function render(image: HTMLImageElement): void {
		const context = canvas?.getContext("2d");
		if (!canvas || !context) {
			throw new Error("No context found for canvas");
		}

		context.clearRect(0, 0, canvas.width, canvas.height);
		context.drawImage(image, 0, 0, canvas.width, canvas.height);
	}

	let frame = 1;
	let currentFrame = 0;

	function createGsapAnimation(container: HTMLElement): ActionReturn {
		if (window.innerWidth <= querySizeMappings.lg) {
			return {};
		}

		const scrollTrigger = ScrollTrigger.create({
			trigger: container,
			start: `top top`,
			end: `+=150%`,
			pin: true,
			scrub: 1.5,
			onUpdate(self) {
				frame = Math.round(100 * self.progress);
			},
		});
		return {
			destroy(): void {
				scrollTrigger.kill();
			},
		};
	}

	$: currentFrame = Math.max(1, Math.floor((frame / 100) * frameCount));
	async function onCurrentFrameChanged(frame: number): Promise<void> {
		if (typeof window === "undefined") {
			return;
		}

		hideHeaderStore.set(frame > 1 && frame < frameCount);

		const image = await getImage(frame, frame > 5 ? frameCount : 10);
		if (currentFrame === frame) {
			render(image);
		}
	}

	$: void onCurrentFrameChanged(currentFrame);

	onMount(async () => {
		if (window.innerWidth <= querySizeMappings.lg) {
			render(await getImage(frameCount, 0));
		} else {
			render(await getImage(1));
			if (preloadElement) {
				const preloadObserver = new IntersectionObserver(
					(entries) => {
						for (const entry of entries) {
							if (entry.isIntersecting) {
								void getImage(1, frameCount);
							}
						}
					},
					{
						threshold: 0.5,
					},
				);
				preloadObserver.observe(preloadElement);
			}
		}
	});

	const translate = getTranslate(translations);
</script>

<div use:createGsapAnimation class="relative mt-48 flex min-h-screen flex-col lg:block">
	<div class="container flex justify-between pt-8 lg:px-[3.875rem]">
		<p
			use:flyInOnScroll
			class="text-text text-xl font-semibold leading-[1.136] lg:w-[40%] lg:text-4xl lg:leading-[5.25rem]"
		>
			{translate("integration")}
		</p>

		<div
			use:flyInOnScroll
			class="hidden flex-col pr-12 text-[1.4375rem] font-light leading-[1.52] text-[#010101] lg:flex"
		>
			<span>{translate("control")}</span>
			<span>{translate("costOptimisation")}</span>
			<span>{translate("flexibility")}</span>
			<span>{translate("subcontracting")}</span>
			<span>{translate("qualityAssurance")}</span>
			<span>{translate("inventoryControl")}</span>
		</div>
	</div>

	<div use:flyInOnScroll class="inset-0 -z-10 grid place-items-center lg:absolute">
		<div class="relative aspect-[3/1] w-full overflow-clip lg:aspect-video">
			<canvas
				bind:this={canvas}
				class="absolute inset-0 top-1/2 aspect-video w-full -translate-y-1/2"
				width={canvas?.clientWidth ?? 0}
				height={((canvas?.clientHeight ?? 0) * 16) / 9}
			/>

			{#if images[currentFrame] === undefined}
				<div class="absolute inset-0 grid place-items-center text-xl">
					<span>{translate("loading")}</span>
				</div>
			{/if}

			<div
				class="absolute inset-0 hidden opacity-0 transition-opacity duration-500 lg:block"
				class:opacity-100={currentFrame > frameCount / 1.5}
			>
				<FactoryLabel label={translate("heatTreatment")} class="absolute bottom-[47%] left-[20%]" />
				<FactoryLabel label={translate("welding")} class="absolute bottom-[42%] left-[10%]" />
				<FactoryLabel label={translate("surfaceFinishes")} class="absolute bottom-[36%] left-[25%]" />
				<FactoryLabel label={translate("lasering")} class="absolute bottom-[40%] left-[34%] " />
				<FactoryLabel label={translate("foundry")} class="absolute bottom-[47%] left-[39%] " />
				<FactoryLabel label={translate("production")} class="absolute bottom-[38%] left-[43%] " />
				<FactoryLabel label={translate("plasticInjection")} class="absolute bottom-[44%] left-[48%] " />
				<FactoryLabel label={translate("toolRoom")} class="absolute bottom-[39%] left-[57%] " />
				<FactoryLabel label={translate("pressingBending")} class="absolute bottom-[47%] left-[63%] " />
				<FactoryLabel label={translate("machining")} class="absolute bottom-[37%] left-[75%] " />
				<FactoryLabel label="R&D" class="absolute bottom-[44%] left-[87%]" />
			</div>
		</div>
	</div>

	<div
		use:flyInOnScroll
		class="container flex flex-col gap-10 pr-12 text-[1.125rem] font-light leading-[1.52] text-[#010101] lg:hidden"
	>
		<div class="text-2md text-primary flex flex-col font-bold">
			<span>{translate("foundry")}</span>
			<span>{translate("plasticInjection")}</span>
			<span>{translate("heatTreatment")}</span>
			<span>{translate("machining")}</span>
			<span>{translate("production")}</span>
			<span>{translate("surfaceFinishes")}</span>
			<span>{translate("lasering")}</span>
			<span>{translate("toolRoom")}</span>
			<span>{translate("pressingBending")}</span>
			<span>{nbspify("R&D")}</span>
			<span>{translate("welding")}</span>
		</div>
		<div class="flex flex-col">
			<span>{translate("control")}</span>
			<span>{translate("costOptimisation")}</span>
			<span>{translate("flexibility")}</span>
			<span>{translate("subcontracting")}</span>
			<span>{translate("qualityAssurance")}</span>
			<span>{translate("inventoryControl")}</span>
		</div>
	</div>

	<Link
		asButton
		href="/{getPathInLocale(locale, technologyPathMap)}"
		class="absolute bottom-8 right-8 hidden h-[9.25rem] w-full lg:flex"
	>
		{translate("showMore")}
	</Link>
</div>
