<script lang="ts">
	import { flyInOnScroll } from "../utils/flyInOnScroll.js";
	import InfoBannerTitle from "./InfoBannerTitle.svelte";
	import Link from "./Link.svelte";
	import StaticVideo from "./Video/StaticVideo.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let video: string;
	export let videoMobile: string;
	export let videoPoster: string | undefined = undefined;
	export let title: string;

	export let buttonText: string | undefined = undefined;
	export let link: string | undefined = undefined;
	export let buttonPosition: "top" | "bottom" = "bottom";
	export let textBlockPosition: "left" | "right" = "left";
</script>

<div class="relative min-h-screen">
	{#if video}
		<div class="absolute inset-0 -z-10">
			<StaticVideo
				{video}
				class="hidden h-full md:block"
				videoClass="h-full w-full object-cover"
				poster={videoPoster}
			/>
			<StaticVideo
				video={videoMobile}
				class="h-full md:hidden"
				videoClass="h-full w-full object-cover"
				poster={videoPoster}
			/>
		</div>
	{/if}

	<div
		class="container flex min-h-[inherit] flex-col items-center justify-between gap-10 p-3 sm:p-5 md:px-[4.375rem] md:py-[3.75rem] lg:flex-row lg:items-end"
	>
		<div
			use:flyInOnScroll
			class="flex w-full max-w-[49.125rem] flex-col justify-between bg-white/90 p-4 pt-8 sm:p-5 md:px-[3.625rem] md:pb-16 md:pt-[4.625rem] lg:max-w-[min(50%,49.125rem)] lg:gap-32 {textBlockPosition ===
			'left'
				? 'justify-self-start'
				: 'justify-self-end lg:ml-auto'}"
		>
			<InfoBannerTitle {title} />
			<slot />
			<div class="absolute inset-0 -z-10 bg-white/90 backdrop-blur"></div>
		</div>
		{#if buttonText}
			<div class="{buttonPosition === 'top' ? 'lg:self-start' : 'lg:self-end'} w-full">
				<Link animated asButton href={link} class="mx-auto lg:mr-0">
					{nbspify(buttonText)}
				</Link>
			</div>
		{/if}
	</div>
</div>
