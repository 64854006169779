<script lang="ts">
	import { onMount } from "svelte";
	import { Locale, type LocalizedString } from "../../../../core/schema/Locale";
	import { getTenant } from "../../contexts/tenantContextKey";
	import { flyInOnScroll } from "../../utils/flyInOnScroll.js";
	import { nbspify } from "../../../../core/utils/nbspify.js";

	const { locale } = getTenant();

	let numbers: { target: number; unit: LocalizedString; current: number | null; text: LocalizedString }[] = [
		{
			target: 85,
			unit: {
				[Locale.cs]: "mil",
				[Locale.en]: "mio",
			},
			current: null,
			text: {
				[Locale.cs]: "produktů ročně",
				[Locale.en]: "products made per year",
			},
		},
		{
			target: 150,
			unit: {
				[Locale.cs]: "+",
				[Locale.en]: "+",
			},
			current: null,
			text: {
				[Locale.cs]: "kamionů denně",
				[Locale.en]: "trucks daily",
			},
		},
		{
			target: 300,
			unit: {
				[Locale.cs]: "+",
				[Locale.en]: "+",
			},
			current: null,
			text: {
				[Locale.cs]: "registrovaných patentů",
				[Locale.en]: "patents registered",
			},
		},
	];

	let isCounting = false;
	const duration = 1000;

	function startCounting(): void {
		if (isCounting) {
			return;
		}
		isCounting = true;
		const startTime = performance.now();

		function updateNumbers(now: number): void {
			const elapsedTime = now - startTime;

			numbers = numbers.map((number) => {
				if (elapsedTime < duration) {
					return {
						...number,
						current: Math.min(number.target, Math.floor((elapsedTime / duration) * number.target)),
					};
				} else {
					return {
						...number,
						current: number.target,
					};
				}
			});

			if (elapsedTime < duration) {
				requestAnimationFrame(updateNumbers);
			}
		}

		requestAnimationFrame(updateNumbers);
	}

	onMount(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					startCounting();
					observer.disconnect();
				}
			});
		});

		observer.observe(document.getElementById("counter-container") as Element);
	});
</script>

<div
	id="counter-container"
	class="container flex flex-col justify-between gap-[5.625rem] py-40 lg:flex-row lg:gap-0 lg:pb-80 lg:pt-8"
>
	{#each numbers as number}
		<div
			use:flyInOnScroll={{ duration: 1.25 }}
			class="flex flex-col items-start justify-center sm:px-6 lg:mt-[12rem] lg:w-[32.1875rem] lg:justify-normal lg:px-0"
		>
			<span class="text-xl font-semibold leading-[1.136] lg:text-4xl lg:leading-[5.25rem]">
				{nbspify((number.current ?? number.target).toString())}
				{nbspify(number.unit[locale])}
			</span>
			<p class="lg:text-2lg m-0 p-0 text-[1.75rem] font-bold leading-[1.2142] lg:leading-[1.3157]">
				{nbspify(number.text[locale])}
			</p>
		</div>
	{/each}
</div>
